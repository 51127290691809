/*
 * Renders a set of options with selected and non-selected values.
 *
 * The corresponding component when selecting the values is
 * FieldCheckboxGroup.
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import includes from 'lodash/includes';

import css from './Animities.module.css';
import IconAmenitiesSelected from '../IconAmenities/IconAmenitiesSelected';
import AmenitiesIcon from '../../assets/amenities.png';
import IconAminitiesCard from '../IconAminitiesCard/IconAminitiesCard';
import IconAminitiesCardDescription from '../IconAminitiesCard/IconAminitiesCardDescription';

const checkSelected = (options, selectedOptions) => {
  return options.map(option => ({
    key: option.key,
    label: option.label,
    isSelected: includes(selectedOptions, option.key),
  }));
};

const IconCheck = props => {
  const isVisible = props.isVisible;
  const classes = isVisible ? css.checkIcon : classNames(css.checkIcon, css.hidden);

  return (
    <svg width="9" height="9" xmlns="http://www.w3.org/2000/svg" className={classes}>
      <path
        className={css.marketplaceFill}
        d="M2.636621 7.7824771L.3573694 5.6447948c-.4764924-.4739011-.4764924-1.2418639 0-1.7181952.4777142-.473901 1.251098-.473901 1.7288122 0l1.260291 1.1254783L6.1721653.505847C6.565577-.0373166 7.326743-.1636902 7.8777637.227582c.5473554.3912721.6731983 1.150729.2797866 1.6951076L4.4924979 7.631801c-.2199195.306213-.5803433.5067096-.9920816.5067096-.3225487 0-.6328797-.1263736-.8637952-.3560334z"
        fillRule="evenodd"
      />
    </svg>
  );
};

const Item = props => {
  const { label, isSelected, icon, heading } = props;

  const labelClass = isSelected ? css.selectedLabel : css.notSelectedLabel;
  const labelSplit = label.split('Dedicated');

  return isSelected ? (
    <li className={css.item}>
      {heading === 'selected_pub_aminities' && <span className={labelClass}>{icon}</span>}
      <div className={css.labelWrapper}>
        <b className={labelClass}>{labelSplit[0]}</b>
        <br />
        <span>Dedicated {labelSplit[1]}</span>
      </div>
    </li>
  ) : null;
};

const Aminities = props => {

  const { options, heading } = props;
  //   const classes = classNames(rootClassName || css.root, className);
  //   const listClasses = twoColumns ? classNames(classes, css.twoColumns) : classes;
  //   const selectedOption = options ? options.slice(0, 3) : null;
  //   const checked = checkSelected(options, selectedOption);

  return (
    <ul className={css.aminitiesWrapper}>
      {options &&
        options.map((option, index) => {
          return (
            <div key={index} className={css.item}>
              <span className={css.amenitiesImage}>
                <IconAminitiesCard type={option.value} />
              </span>
              <div className={css.amenitiesLabel}>
                <div className={css.amenitiesHeading}>{option?.label}</div>
                <span className={css.amenitiesDesc}><IconAminitiesCardDescription type={option.value} /></span>
              </div>
            </div>

            //  <Item
            //     heading={heading}
            //    key={option.key}
            //      label={option.label}
            //  icon={<IconAmenitiesSelected name={option.label}
            //     />}
            //  />
          );
        })}
    </ul>
  );
};

Aminities.defaultProps = {
  rootClassName: null,
  className: null,
  selectedOptions: [],
  twoColumns: false,
};

const { arrayOf, bool, node, shape, string } = PropTypes;

Aminities.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  options: arrayOf(
    shape({
      key: string.isRequired,
      label: node.isRequired,
    })
  ),
  selectedOptions: arrayOf(string),
  twoColumns: bool,
};

export default Aminities;
