import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';


import css from './IconAminitiesCard.module.css';


const certified_fully_private = 'Certified Fully Private';
const av_available = "AV Available";
const digital_payments = "Digital Payments";
const nearby_accommodations = "Nearby Accommodations";
const celebrated_for_product_launches = "Celebrated for Product Launches";
const chosen_for_vip_dinners = "Chosen for VIP Dinners";
const client_grade_food_options = "Client-Grade Food Options";
const customizable_layout = "Customizable Layout";
const dedicated_event_coordinator = "Dedicated Event Coordinator";
const dedicated_parking = "Dedicated Parking";
const go_to_for_charity_galas = "Go-To for Charity Galas";
const go_to_for_networking_events = "Go-To for Networking Events";
const outdoor_dining = "Outdoor Dining";
const popular_for_corporate_luncheons = "Popular for Corporate Luncheons";
const popular_for_team_nights_out = "Popular for Team Nights Out";
const rooftop_access = "Rooftop Access";
const sommelier_on_site = "Sommelier On-Site";
const vip_executive_rooms = "VIP & Executive Rooms";
const waterfront_view = "Waterfront View";

const IconAminitiesCardDescription = props => {
  const { className, rootClassName, type } = props;
  const classes = classNames(rootClassName || css.root, className);

  switch (type) {
    case certified_fully_private:
      return (
        "Ensure complete discretion and privacy, ideal for sensitive events."
      );
    case av_available:
      return (
        "Advanced audiovisual tech for flawless presentations and displays."
      );
    case digital_payments:
      return (
        "Keep the corporate card at the office, this venue can send you a digital invoice instead."
      )
    case nearby_accommodations:
      return (
        "Close to the city's best hotels for the convenience of distant attendees."
      )
    case celebrated_for_product_launches:
      return (
        "The venue's aesthetics and facilities magnify the excitement around new products."
      );
    case chosen_for_vip_dinners:
      return (
        "Top-rated for executive and important stakeholder dinners."
      );
    case client_grade_food_options:
      return (
        "Gourmet offerings fit for high-end guests."
      );
    case customizable_layout:
      return (
        "Flexibility to rearrange seating into reception, seated dinner, or food displays."
      );
    case dedicated_event_coordinator:
      return (
        "An in-house coordinator to assist with event planning and logistics."
      );
    case dedicated_parking:
      return (
        "Hassle-free parking available for guest convenience."
      );
    case go_to_for_charity_galas:
      return (
        "This venue has hosted a number of charitable events."
      );
    case go_to_for_networking_events:
      return (
        "Offers the ideal ambiance for mingling and establishing business connections."
      );
    case outdoor_dining:
      return (
        "This venue offers an outdoor area to enjoy the beautiful weather."
      );
    case popular_for_corporate_luncheons:
      return (
        "Mid-day events shine at this venue, from formal lunches to casual team get-togethers."
      );
    case popular_for_team_nights_out:
      return (
        "A top pick for memorable corporate bonding after work."
      );
    case rooftop_access:
      return (
        "Venue offers a rooftop area, perfect for open-air events or city views."
      );
    case sommelier_on_site:
      return (
        "Enhance dining with curated wine insights and pairings."
      );
    case vip_executive_rooms:
      return (
        "Private dining rooms exclusively for distinguished guests."
      );
    case waterfront_view:
      return (
        "Breathtaking waterfront vistas to make events unforgettable."
      )
    default:
      return (
        <></>
      );
  }
};

IconAminitiesCardDescription.defaultProps = {
  className: null,
  rootClassName: null,
  type: 'default',
};

IconAminitiesCardDescription.propTypes = {
  className: string,
  rootClassName: string,
  type: string,
};

export default IconAminitiesCardDescription;
