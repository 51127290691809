import React, { useEffect, useState } from 'react';
import { array, arrayOf, bool, func, shape, string, oneOf, object, number } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { FormattedMessage, intlShape, useIntl } from '../../util/reactIntl';
import { LISTING_STATE_PENDING_APPROVAL, LISTING_STATE_CLOSED, propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
} from '../../util/urlHelpers';
import { convertMoneyToNumber } from '../../util/currency';
import {
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { richText } from '../../util/richText';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/ui.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';

import {
  H4,
  Page,
  NamedLink,
  NamedRedirect,
  OrderPanel,
  LayoutSingleColumn,
  IconCard,
} from '../../components';

import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterContainer from '../FooterContainer/FooterContainer';
import NotFoundPage from '../NotFoundPage/NotFoundPage';

import {
  sendInquiry,
  setInitialValues,
  fetchTimeSlots,
  fetchTransactionLineItems,
} from './ListingPage.duck';

import {
  LoadingPage,
  ErrorPage,
  priceData,
  listingImages,
  handleContactUser,
  handleSubmitInquiry,
  handleSubmit,
} from './ListingPage.shared';
import ActionBarMaybe from './ActionBarMaybe';
import SectionTextMaybe from './SectionTextMaybe';
import SectionReviews from './SectionReviews';
import SectionAuthorMaybe from './SectionAuthorMaybe';
import SectionMapMaybe from './SectionMapMaybe';
import SectionGallery from './SectionGallery';
import css from './ListingPage.module.css';
import FieldDateAndTimeInput from '../../components/OrderPanel/BookingTimeForm/FieldDateAndTimeInput.js';
import Aminities from '../../components/Aminities/AminitiesComponent.js';
import classNames from 'classnames';

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;

const { UUID } = sdkTypes;

export const OpenPdfButtons = ({ pdfUrls }) => {
  const handleOpenPdf = pdfUrl => {
    window.open(pdfUrl, '_blank');
  };
  return (
    <div className={css.pdfUrlButton}>
      {pdfUrls &&
        pdfUrls?.map((pdfUrl, index) => (
          <div className={css.pdfWrapper} onClick={() => handleOpenPdf(pdfUrl)}>
            <iframe src={pdfUrl} type="application/pdf" width="100%" height="100%" />
            <span className={css.pdfName}>
              <span>PRIVATE EVENTS FOOD</span>
            </span>
          </div>
        ))}
    </div>
  );
};
export const AllEventTeamImages = ({ eventImagesUrls }) => {
  const handleEventTeamImages = eventImagesUrls => {
    window.open(eventImagesUrls, '_blank');
  };

  return (
    <div className={css.imagesRound}>
      {eventImagesUrls &&
        eventImagesUrls?.map((imageUrl, index) => (
          <div
            key={index}
            onClick={() => handleEventTeamImages(imageUrl)}
            className={css.eventImages}
          >
            <img src={imageUrl} alt={imageUrl} />
          </div>
        ))}
    </div>
  );
};

export const ListingPageComponent = props => {
  const [inquiryModalOpen, setInquiryModalOpen] = useState(
    props.inquiryModalOpenForListingId === props.params.id
  );
  const [faqData, setFaqData] = useState(false);
  const [loader, setLoader] = useState(false);
  const [buttonLabel, setButtonLabel] = useState('');
  const [selectedDate, setSelectedDate] = useState('');

  const {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    intl,
    onManageDisableScrolling,
    params: rawParams,
    location,
    scrollingDisabled,
    showListingError,
    reviews,
    fetchReviewsError,
    sendInquiryInProgress,
    sendInquiryError,
    monthlyTimeSlots,
    onFetchTimeSlots,
    listingConfig: listingConfigProp,
    onFetchTransactionLineItems,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    history,
    callSetInitialValues,
    onSendInquiry,
    onInitializeCardPaymentData,
    config,
    routeConfiguration,
    viewport,
  } = props;

  const listingFields = config.listing.listingFields;
  const handleToggleFaqHandler = index => {
    setFaqData(prevVisibleAnswers => {
      // Toggle the visibility of the answer for the clicked question
      return {
        ...prevVisibleAnswers,
        [index]: !prevVisibleAnswers[index],
      };
    });
  };
  const listingConfig = listingConfigProp || config.listing;
  const listingId = new UUID(rawParams.id);
  const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
  const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
  const currentListing =
    isPendingApprovalVariant || isDraftVariant
      ? ensureOwnListing(getOwnListing(listingId))
      : ensureListing(getListing(listingId));
  const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
  const params = { slug: listingSlug, ...rawParams };

  const listingPathParamType = isDraftVariant
    ? LISTING_PAGE_PARAM_TYPE_DRAFT
    : LISTING_PAGE_PARAM_TYPE_EDIT;
  const listingTab = isDraftVariant ? 'photos' : 'details';
  const isApproved =
    currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;
  const dateFormattingOptions = { month: 'short', day: 'numeric', weekday: 'short' };
  const pendingIsApproved = isPendingApprovalVariant && isApproved;
  const TODAY = new Date();

  // If a /pending-approval URL is shared, the UI requires
  // authentication and attempts to fetch the listing from own
  // listings. This will fail with 403 Forbidden if the author is
  // another user. We use this information to try to fetch the
  // public listing.
  const pendingOtherUsersListing =
    (isPendingApprovalVariant || isDraftVariant) &&
    showListingError &&
    showListingError.status === 403;
  const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

  if (shouldShowPublicListingPage) {
    return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
  }
  const topbar = <TopbarContainer className={classNames(css.topbar, css.listingTopbar)} />;

  if (showListingError && showListingError.status === 404) {
    // 404 listing not found
    return <NotFoundPage />;
  } else if (showListingError) {
    // Other error in fetching listing
    return <ErrorPage topbar={topbar} scrollingDisabled={scrollingDisabled} intl={intl} />;
  } else if (!currentListing.id) {
    // Still loading the listing
    return <LoadingPage topbar={topbar} scrollingDisabled={scrollingDisabled} intl={intl} />;
  }

  const {
    description = '',
    geolocation = null,
    price = null,
    title = '',
    publicData = {},
    metadata = {},
  } = currentListing.attributes;
  const {
    restaurantDescription,
    restaurantSeatedSeats,
    location: listingLocation,
    menuLink,
    quote_kicker,
    faq,
    pub_total_capacity,
    selected_pub_aminities,
    videoLink,
    eventImagess,
    dayAvailability,
  } = currentListing.attributes.publicData || {};
  const restauranttype = listingFields?.filter(e => e.key == 'restaurant_type');
  const restaurantTypes = restauranttype[0]?.enumOptions;
  const selectedOption = publicData?.restaurant_type;
  const selectresturantType = publicData?.price_Trier;
  const restaurantdollartype = listingFields?.filter(e => e.key == 'price_Trier');
  const restaurantdollarTypes = restaurantdollartype[0]?.enumOptions;
  const dollerType = restaurantdollarTypes.find(e => e.option === selectresturantType);
  const event = restaurantTypes.find(e => e.option === selectedOption);

  const selectedAmenities = selected_pub_aminities?.map(sa => ({ ...sa, key: sa.key + '' }));

  const availabeDay = dayAvailability?.map(item => item.dayOfWeek);
  const { address, building } = listingLocation || {};
  const pdfUrls = menuLink;
  const eventImagesUrls = eventImagess;
  const faqs = faq;

  const richTitle = (
    <span>
      {richText(title, {
        longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
        longWordClass: css.longWord,
      })}
    </span>
  );

  const authorAvailable = currentListing && currentListing.author;
  const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
  const isOwnListing =
    userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;

  const currentAuthor = authorAvailable ? currentListing.author : null;
  const ensuredAuthor = ensureUser(currentAuthor);

  // When user is banned or deleted the listing is also deleted.
  // Because listing can be never showed with banned or deleted user we don't have to provide
  // banned or deleted display names for the function
  const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

  const { formattedPrice } = priceData(price, config.currency, intl);

  const commonParams = { params, history, routes: routeConfiguration };
  const onContactUser = handleContactUser({
    ...commonParams,
    currentUser,
    callSetInitialValues,
    location,
    setInitialValues,
    setInquiryModalOpen,
  });
  // Note: this is for inquiry state in booking and purchase processes. Inquiry process is handled through handleSubmit.
  const onSubmitInquiry = handleSubmitInquiry({
    ...commonParams,
    getListing,
    onSendInquiry,
    setInquiryModalOpen,
  });
  const onSubmit = handleSubmit({
    ...commonParams,
    currentUser,
    callSetInitialValues,
    getListing,
    onInitializeCardPaymentData,
  });

  const handleOrderSubmit = async values => {
    try {
      const isCurrentlyClosed = currentListing.attributes.state === LISTING_STATE_CLOSED;
      if (isOwnListing || isCurrentlyClosed) {
        window.scrollTo(0, 0);
      } else {
        onSubmit(values);
      }
    } catch (e) {
      console.log(e, 'error');
    }
  };
  const facebookImages = listingImages(currentListing, 'facebook');
  const twitterImages = listingImages(currentListing, 'twitter');
  const schemaImages = listingImages(
    currentListing,
    `${config.layout.listingImage.variantPrefix}-2x`
  ).map(img => img.url);
  const marketplaceName = config.marketplaceName;
  const schemaTitle = intl.formatMessage(
    { id: 'ListingPage.schemaTitle' },
    { title, price: formattedPrice, marketplaceName }
  );
  // You could add reviews, sku, etc. into page schema
  // Read more about product schema
  // https://developers.google.com/search/docs/advanced/structured-data/product
  const productURL = `${config.marketplaceRootURL}${location.pathname}${location.search}${location.hash}`;
  const schemaPriceMaybe = price
    ? {
        price: intl.formatNumber(convertMoneyToNumber(price), {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        priceCurrency: price.currency,
      }
    : {};
  const currentStock = currentListing.currentStock?.attributes?.quantity || 0;
  const schemaAvailability =
    currentStock > 0 ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock';

  const createFilterOptions = options =>
    options?.map(o => ({ key: `${o.option}`, label: o.label }));
  const transformOptionLabel = option => {
    return option.charAt(0).toUpperCase() + option.slice(1).replace(/-/g, ' ');
  };
  const timeZone = currentListing?.attributes?.availabilityPlan?.timezone;
  const isMobileLayout = viewport?.width < 660;
  return (
    <Page
      title={schemaTitle}
      scrollingDisabled={scrollingDisabled}
      author={authorDisplayName}
      description={description}
      facebookImages={facebookImages}
      twitterImages={twitterImages}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'Product',
        description: description,
        name: schemaTitle,
        image: schemaImages,
        offers: {
          '@type': 'Offer',
          url: productURL,
          ...schemaPriceMaybe,
          availability: schemaAvailability,
        },
      }}
    >
      <LayoutSingleColumn className={css.pageRoot} topbar={topbar} footer={<FooterContainer />}>
        <div className={css.topbarActionGallery}>
          {currentListing.id ? (
            <ActionBarMaybe
              className={css.actionBarForProductLayout}
              isOwnListing={isOwnListing}
              listing={currentListing}
              editParams={{
                id: listingId.uuid,
                slug: listingSlug,
                type: listingPathParamType,
                tab: listingTab,
              }}
            />
          ) : null}
          <SectionGallery
            listing={currentListing}
            variantPrefix={config.layout.listingImage.variantPrefix}
          />
        </div>
        <div className={css.contentWrapperForProductLayout}>
          <div className={css.listingRow}>
            <div className={css.mainColumnForProductLayout}>
              <div className={css.singleUserDetailListing}>
                <div>
                  <div className={css.titleName}>
                    <h4>{title}</h4>
                  </div>
                  <div className={css.priceBox}>
                    <div>{dollerType?.label}</div>
                    <div>| {event?.label}</div>
                  </div>
                </div>

                <div className={css.seatingRow}>
                  <span>{restaurantSeatedSeats} Seated</span>
                  <span>|</span>
                  <span>{pub_total_capacity} Standing</span>
                </div>
                <div className={css.totalReview}>
                  <IconCard brand="iconReview" />
                  <span>
                    4.8 Stars | <i>{quote_kicker}</i>
                  </span>
                </div>

                <div className={css.eventTeam}>
                  <h4>Your Event Team</h4>
                  <AllEventTeamImages eventImagesUrls={eventImagesUrls} />
                </div>
                <Aminities
                  id={'ListingPageCarousal.Amenities'}
                  options={selectedAmenities}
                  heading={'selected_pub_aminities'}
                />

                <div className={css.restaurantDescription}>
                  <div className={css.restaurantHeading}>More about the place</div>
                  <div className={css.resturentData}>{restaurantDescription}</div>
                </div>
              </div>

              <div className={css.exploreMenu}>
                <h4 className={css.exploreHeading}>Explore the Menus</h4>
                <OpenPdfButtons pdfUrls={pdfUrls} />
              </div>
              <SectionTextMaybe text={description} showAsIngress />
              <FinalForm
                {...props}
                onSubmit={handleOrderSubmit}
                render={fieldRenderProps => {
                  const {
                    endDatePlaceholder,
                    startDatePlaceholder,
                    form,
                    pristine,
                    values,
                  } = fieldRenderProps;

                  useEffect(() => {
                    // const startDate = values?.bookingStartDate ? values?.bookingStartDate.date : null;
                    // const endDate = values?.bookingEndDate ? values?.bookingEndDate.date : null;
                    setSelectedDate(values);
                  }, [values.bookingStartDate, values.bookingEndDate]);

                  const bookingStartTime = values.bookingStartTime;
                  const bookingEndTime = values.bookingEndTime;
                  const { buyoutUpdatedData, buyoutFilterOptions } = publicData;
                  const isUpdatedBuyoutData =
                    buyoutUpdatedData &&
                    buyoutUpdatedData.find(
                      data =>
                        data.bookingStartTime === bookingStartTime &&
                        data.bookingEndTime === bookingEndTime
                    );
                  const startDate = values?.bookingStartDate
                    ? values?.bookingStartDate.date
                    : TODAY;

                  // Convert string to Date object
                  var date = new Date(startDate);

                  // Define days of the week and months
                  var daysOfWeek = [
                    'Sunday',
                    'Monday',
                    'Tuesday',
                    'Wednesday',
                    'Thursday',
                    'Friday',
                    'Saturday',
                  ];
                  var months = [
                    'January',
                    'February',
                    'March',
                    'April',
                    'May',
                    'June',
                    'July',
                    'August',
                    'September',
                    'October',
                    'November',
                    'December',
                  ];

                  // Get day, month, and year components
                  var dayOfWeek = daysOfWeek[date.getUTCDay()];
                  var dayOfMonth = date.getUTCDate();
                  var month = months[date.getUTCMonth()];
                  var year = date.getUTCFullYear();

                  var formattedDate = `Availability On ${dayOfWeek}, ${month} ${dayOfMonth}, ${year}:`;

                  return (
                    <div className={css.restaurantBox}>
                      <h4 className={css.sectionSubtitle}>Restaurant Availability</h4>
                      {monthlyTimeSlots && timeZone && (
                        <div className={css.calendarWrapper}>
                          <div>
                            <FieldDateAndTimeInput
                              startDateInputProps={{
                                label: intl.formatMessage({
                                  id: 'BookingTimeForm.bookingStartTitle',
                                }),
                                placeholderText: startDatePlaceholder,
                              }}
                              endDateInputProps={{
                                label: intl.formatMessage({
                                  id: 'BookingTimeForm.bookingEndTitle',
                                }),
                                placeholderText: endDatePlaceholder,
                              }}
                              className={css.bookingDates}
                              listingId={listingId}
                              onFetchTimeSlots={onFetchTimeSlots}
                              monthlyTimeSlots={monthlyTimeSlots}
                              values={values}
                              intl={intl}
                              form={form}
                              pristine={pristine}
                              timeZone={timeZone}
                              dayCountAvailableForBooking={
                                config.stripe.dayCountAvailableForBooking
                              }
                              isListingCalenderVisible={'SHOW'}
                              numberOfMonths={isMobileLayout ? 1 : 2}
                            />
                          </div>
                          {dayOfWeek !== undefined &&
                            dayOfMonth !== undefined &&
                            month !== NaN &&
                            dayOfMonth !== NaN && (
                              <div className={css.buttonsGroup}>
                                <h4 className={css.selectedDate}>{formattedDate}</h4>
                                {/* Check if bookingStartTime and bookingEndTime match any entry in updatedBuyoutData */}
                                <div className={css.filterButton}>
                                  {isUpdatedBuyoutData
                                    ? // Display buyoutName if there's a match
                                      // <p> {isUpdatedBuyoutData?.buyoutName} is Already Booked.</p>
                                      buyoutFilterOptions &&
                                      buyoutFilterOptions?.map((data, index) => {
                                        // Filter out the option with the same buyoutName as isUpdatedBuyoutData
                                        if (data.buyoutName !== isUpdatedBuyoutData?.buyoutName) {
                                          return (
                                            data.buyoutName && (
                                              <div key={index}>
                                                <button className={css.calendarBtn}>
                                                  {transformOptionLabel(data.buyoutName)}
                                                </button>
                                              </div>
                                            )
                                          );
                                        }
                                        return null; // Exclude the option from rendering
                                      })
                                    : // Display message if there's no match
                                      buyoutFilterOptions &&
                                      buyoutFilterOptions?.map((data, index) => {
                                        return (
                                          data.buyoutName && (
                                            <div key={index}>
                                              <button
                                                className={css.calendarBtn}
                                                onClick={() => {
                                                  setButtonLabel({
                                                    ...data,
                                                    selectedOptionIndex: index,
                                                  });
                                                }}
                                              >
                                                {transformOptionLabel(data.buyoutName)}
                                              </button>
                                            </div>
                                          )
                                        );
                                      })}
                                </div>
                              </div>
                            )}
                        </div>
                      )}
                    </div>
                  );
                }}
              />

              {reviews.length > 0 && (
                <SectionReviews reviews={reviews} fetchReviewsError={fetchReviewsError} />
              )}
            </div>
            <div className={css.orderColumnForProductLayout}>
              <div className={css.orderPanelWrapper}>
                <OrderPanel
                  className={css.productOrderPanel}
                  listing={currentListing}
                  isOwnListing={isOwnListing}
                  onSubmit={handleOrderSubmit}
                  selectedDate={selectedDate}
                  buttonLabel={buttonLabel}
                  loader={loader}
                  authorLink={
                    <NamedLink
                      className={css.authorNameLink}
                      name="ListingPage"
                      params={params}
                      to={{ hash: '#author' }}
                    >
                      {authorDisplayName}
                    </NamedLink>
                  }
                  title={
                    <FormattedMessage id="ListingPage.orderTitle" values={{ title: richTitle }} />
                  }
                  titleDesktop={
                    <H4 as="h1" className={css.orderPanelTitle}>
                      <FormattedMessage id="ListingPage.orderTitle" values={{ title: richTitle }} />
                    </H4>
                  }
                  author={ensuredAuthor}
                  onManageDisableScrolling={onManageDisableScrolling}
                  onContactUser={onContactUser}
                  monthlyTimeSlots={monthlyTimeSlots}
                  onFetchTimeSlots={onFetchTimeSlots}
                  onFetchTransactionLineItems={onFetchTransactionLineItems}
                  lineItems={lineItems}
                  fetchLineItemsInProgress={fetchLineItemsInProgress}
                  fetchLineItemsError={fetchLineItemsError}
                  validListingTypes={config.listing.listingTypes}
                  marketplaceCurrency={config.currency}
                  dayCountAvailableForBooking={config.stripe.dayCountAvailableForBooking}
                  marketplaceName={config.marketplaceName}
                />
              </div>
            </div>
          </div>
          <h4 className={css.sectionSubtitle}>Where in the City You'll be </h4>
          <SectionMapMaybe
            geolocation={geolocation}
            publicData={publicData}
            listingId={currentListing.id}
            mapsConfig={config.maps}
          />
          <div className={css.mobilePadding}>
            <SectionAuthorMaybe
              title={title}
              listing={currentListing}
              authorDisplayName={authorDisplayName}
              onContactUser={onContactUser}
              isInquiryModalOpen={isAuthenticated && inquiryModalOpen}
              onCloseInquiryModal={() => setInquiryModalOpen(false)}
              sendInquiryError={sendInquiryError}
              sendInquiryInProgress={sendInquiryInProgress}
              onSubmitInquiry={onSubmitInquiry}
              currentUser={currentUser}
              onManageDisableScrolling={onManageDisableScrolling}
            />
            <div className={css.faqBox}>
              {(faqs || []).map((item, index) => {
                return (
                  <div key={index} className={css.faqAccordance}>
                    <div
                      onClick={() => handleToggleFaqHandler(index)}
                      className={`${css.questionList} ${faqData[index] ? css.open : ''}`}
                    >
                      <span>
                        {item.questionName.charAt(0).toUpperCase() + item.questionName.slice(1)}
                      </span>
                    </div>
                    {faqData[index] && (
                      <div className={css.questionLabel}>{item.questionAnswer}</div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </LayoutSingleColumn>
    </Page>
  );
};

ListingPageComponent.defaultProps = {
  currentUser: null,
  inquiryModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  monthlyTimeSlots: null,
  sendInquiryError: null,
  listingConfig: null,
  lineItems: null,
  fetchLineItemsError: null,
};

ListingPageComponent.propTypes = {
  // from useHistory
  history: shape({
    push: func.isRequired,
  }).isRequired,
  // from useLocation
  location: shape({
    search: string,
  }).isRequired,

  // from useIntl
  intl: intlShape.isRequired,

  // from useConfiguration
  config: object.isRequired,
  // from useRouteConfiguration
  routeConfiguration: arrayOf(propTypes.route).isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  // viewport: shape({
  //   width: number.isRequired,
  //   height: number.isRequired,
  // }).isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  inquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  reviews: arrayOf(propTypes.review),
  fetchReviewsError: propTypes.error,
  monthlyTimeSlots: object,
  // monthlyTimeSlots could be something like:
  // monthlyTimeSlots: {
  //   '2019-11': {
  //     timeSlots: [],
  //     fetchTimeSlotsInProgress: false,
  //     fetchTimeSlotsError: null,
  //   }
  // }
  sendInquiryInProgress: bool.isRequired,
  sendInquiryError: propTypes.error,
  onSendInquiry: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  listingConfig: object,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
};

const EnhancedListingPage = props => {
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();

  return (
    <ListingPageComponent
      config={config}
      routeConfiguration={routeConfiguration}
      intl={intl}
      history={history}
      location={location}
      {...props}
    />
  );
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.auth;
  const {
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    sendInquiryInProgress,
    sendInquiryError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    inquiryModalOpenForListingId,
  } = state.ListingPage;

  const { currentUser } = state.user;
  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    inquiryModalOpenForListingId,
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    sendInquiryInProgress,
    sendInquiryError,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onFetchTransactionLineItems: params => dispatch(fetchTransactionLineItems(params)),
  onSendInquiry: (listing, message) => dispatch(sendInquiry(listing, message)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onFetchTimeSlots: (listingId, start, end, timeZone) =>
    dispatch(fetchTimeSlots(listingId, start, end, timeZone)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(connect(mapStateToProps, mapDispatchToProps))(EnhancedListingPage);

export default ListingPage;
