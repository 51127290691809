import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import fullyPrivate from '../../assets/images/amenities/certified_fully_private.png';
import avCable from '../../assets/images/amenities/av-cable.png';
import digitalPayments from '../../assets/images/amenities/bill.png';
import nearbyAccommodations from '../../assets/images/amenities/location.png';
import productLaunches from '../../assets/images/amenities/champagne.png';
import vipDinners from '../../assets/images/amenities/vip-dinner.png';
import clientGrade from '../../assets/images/amenities/lobster.png'
import customizableLayout from '../../assets/images/amenities/dining-chair.png';
import dedicatedEvent from '../../assets/images/amenities/clipboard.png';
import dedicatedParking from '../../assets/images/amenities/car.png';
import forCharity from '../../assets/images/amenities/champagne-glass.png'
import forNetworking from '../../assets/images/amenities/alcoholic-drinks.png';
import outdoorDining from '../../assets/images/amenities/terrace.png';
import corporateLuncheons from '../../assets/images/amenities/sun.png';
import teamNights from '../../assets/images/amenities/shot-glass.png';
import rooftopAccess from '../../assets/images/amenities/skyline.png';
import sommelierSite from '../../assets/images/amenities/wine.png';
import vipRooms from '../../assets/images/amenities/dining-room.png'
import waterfrontView from '../../assets/images/amenities/waterfront.png';
import dummyIcon from '../../assets/images/amenities/dummy.png';

import css from './IconAminitiesCard.module.css';


const certified_fully_private = 'Certified Fully Private';
const av_available = "AV Available";
const digital_payments = "Digital Payments";
const nearby_accommodations = "Nearby Accommodations";
const celebrated_for_product_launches = "Celebrated for Product Launches";
const chosen_for_vip_dinners = "Chosen for VIP Dinners";
const client_grade_food_options = "Client-Grade Food Options";
const customizable_layout = "Customizable Layout";
const dedicated_event_coordinator = "Dedicated Event Coordinator";
const dedicated_parking = "Dedicated Parking";
const go_to_for_charity_galas = "Go-To for Charity Galas";
const go_to_for_networking_events = "Go-To for Networking Events";
const outdoor_dining = "Outdoor Dining";
const popular_for_corporate_luncheons = "Popular for Corporate Luncheons";
const popular_for_team_nights_out = "Popular for Team Nights Out";
const rooftop_access = "Rooftop Access";
const sommelier_on_site = "Sommelier On-Site";
const vip_executive_rooms = "VIP & Executive Rooms";
const waterfront_view = "Waterfront View";

const IconAminitiesCard = props => {
  const { className, rootClassName, type } = props;
  const classes = classNames(rootClassName || css.root, className);

  switch (type) {
    case certified_fully_private:
      return (
        <img src={fullyPrivate} />
      );
    case av_available:
      return (
        <img src={avCable} />
      );
    case digital_payments:
      return (
        <img src={digitalPayments} />
      )
    case nearby_accommodations:
      return (
        <img src={nearbyAccommodations} />
      )
    case celebrated_for_product_launches:
      return (
        <img src={productLaunches} />
      );
    case chosen_for_vip_dinners:
      return (
        <img src={vipDinners} />
      );
    case client_grade_food_options:
      return (
        <img src={clientGrade} />
      );
    case customizable_layout:
      return (
        <img src={customizableLayout} />
      );
    case dedicated_event_coordinator:
      return (
        <img src={dedicatedEvent} />
      );
    case dedicated_parking:
      return (
        <img src={dedicatedParking} />
      );
    case go_to_for_charity_galas:
      return (
        <img src={forCharity} />
      );
    case go_to_for_networking_events:
      return (
        <img src={forNetworking} />
      );
    case outdoor_dining:
      return (
        <img src={outdoorDining} />
      );
    case popular_for_corporate_luncheons:
      return (
        <img src={corporateLuncheons} />
      );
    case popular_for_team_nights_out:
      return (
        <img src={teamNights} />
      );
    case rooftop_access:
      return (
        <img src={rooftopAccess} />
      );
    case sommelier_on_site:
      return (
        <img src={sommelierSite} />
      );
    case vip_executive_rooms:
      return (
        <img src={vipRooms} />
      );
    case waterfront_view:
      return (
        <img src={waterfrontView} />
      )
    default:
      return (
        <img src={dummyIcon} />
      );
  }
};

IconAminitiesCard.defaultProps = {
  className: null,
  rootClassName: null,
  type: 'default',
};

IconAminitiesCard.propTypes = {
  className: string,
  rootClassName: string,
  type: string,
};

export default IconAminitiesCard;
